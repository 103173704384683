import { FullAuditedEntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import {
  ChannelIntegrationDto,
  ChannelIntegrationCreateUpdateDto,
  ServiceIntegrationCreateUpdateDto,
  ServiceIntegrationDto,
} from 'src/app/integrations/proxy/integration.model';
import { AgentCreateDto, VirtualAgent } from 'src/app/virtual-agents/proxy';
import { FlowType, ProjectVersionDto } from '../../shared/designer/proxy/designer.model';
import { OutboundRulesCreateUpdateDto } from './outbound/outbound-rules.models';

export interface GetProjectsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  name?: string;
  description?: string;
}

export type GetCustomDataKeysInput = PagedAndSortedResultRequestDto;

export interface ProjectCreateUpdateDto {
  name: string;
  description: string;
  primaryLanguageCode: string;
  isOutbound: boolean;
  additionalLanguageCodes: string[];
  channelIntegrations: ChannelIntegrationCreateUpdateDto[];
  serviceIntegrations: ServiceIntegrationCreateUpdateDto[];
  virtualAgents: AgentCreateDto[];
  outboundRules: OutboundRulesCreateUpdateDto;
}

export interface ProjectVirtualAgentDto {
  name: string;
  id: string;
}

export interface ProjectDto extends FullAuditedEntityDto<string> {
  name?: string;
  currentVersionId?: string;
  publishedProjectVersionId?: string;
  publishedDate?: Date | string;
  isGracefulPublishEnabled?: boolean;
  isSpellCorrectionEnabled?: boolean;
  isTranslationServiceEnabled?: boolean;
  projectVersions?: ProjectVersionDto[];
  channelIntegrations?: ChannelIntegrationDto[];
  serviceIntegrations?: ServiceIntegrationDto[];
  virtualAgents?: ProjectVirtualAgentDto[];
  isProjectFlowValid: boolean;
}

export interface ProjectFilterDto {
  id: string;
  name?: string;
  virtualAgents?: ProjectVirtualAgentDto[];
}

export interface IntentFilterDto {
  intentId: string;
  name?: string;
}

export interface ProjectWithDetailsDto extends FullAuditedEntityDto<string> {
  name?: string;
  description?: string;
  timezone?: string;
  isGracefulPublishEnabled?: boolean;
  isSpellCorrectionEnabled?: boolean;
  isTranslationServiceEnabled?: boolean;
  isOutbound?: boolean;
  channelIntegrations?: ChannelIntegrationDto[];
  serviceIntegrations?: ServiceIntegrationDto[];
  confirmationPhrases?: ConfirmationPhraseDto[];
  virtualAgents?: VirtualAgent[];
  currentVersion?: ProjectVersionDto;
}

export interface PhraseTag {
  id: string;
  value: string;
}

export interface LanguageConfirmationPhrasesModel {
  positivePhrases: PhraseTag[];
  negativePhrases: PhraseTag[];
}

export interface ConfirmationPhraseDto extends FullAuditedEntityDto<string> {
  value: string;
  languageId: string;
  projectId: string;
  type: ConfirmationPhraseType;
}

export interface ConfirmationPhraseUpdateDto {
  id?: string;
  value: string;
  languageId: string;
  projectId: string;
  type: ConfirmationPhraseType;
}

export enum ConfirmationPhraseType {
  positive = 0,
  negative = 1,
}

export enum TrainStatus {
  notTrained = 0,
  trained = 1,
}

export enum GenerativeQuestionAnsweringTrainStatus {
  notTrained = 0,
  training = 1,
  trained = 2,
  obsolete = 3,
  failed = 4,
}

export enum GenerativeQuestionAnsweringFileType {
  general = 0,
  faq = 1,
}

export interface ChatbotDto {
  tenant: string;
  project: string;
  isPublished: boolean;
  isTest: boolean;
}

export interface ProjectImportDto {
  file: File;
}

export interface TimeZoneInfoDtoPagedResultDto {
  items?: TimeZoneInfoDto[];
  totalCount: number;
}

export interface TimeZoneInfoDto {
  displayName?: string;
}

export interface ProjectFlowsAndVirtualAgentsDto {
  projectId: string;
  projectName: string;
  projectVersionId: string;
  isOutboundProject: boolean;
  flowSummaries: ProjectFlowSummaryDto[];
  virtualAgentSummaries: ProjectVirtualAgentSummaryDto[];
}

export interface FlowVaNavigatorMenuItemModel {
  id: string;
  name: string;
  reducedName: string;
}

export interface ProjectFlowSummaryDto extends FlowVaNavigatorMenuItemModel {
  flowType: FlowType;
}

export type ProjectVirtualAgentSummaryDto = FlowVaNavigatorMenuItemModel;

export interface ProjectFlowsAndVirtualAgentsCacheModel {
  selectedProjectFlowId: string;
  selectedVirtualAgentId: string;
  projectFlowsAndVirtualAgents: ProjectFlowsAndVirtualAgentsDto;
}

export interface GenerativeQuestionAnsweringPromptDto {
  prompt: string;
}

export interface GenerativeQuestionAnsweringFileDto {
  originalName: string;
  contentType: string;
  storageObjectName: string;
  sizeInBytes: number;
}

export interface GenerativeQuestionAnsweringUrlDto {
  url: string;
  excludeUrls: GenerativeQuestionAnsweringExcludeUrlDto[];
}

export interface GenerativeQuestionAnsweringUrlCreateDto {
  url: string;
  excludeUrls: GenerativeQuestionAnsweringExcludeUrlCreateDto[];
}

export interface GenerativeQuestionAnsweringExcludeUrlDto extends FullAuditedEntityDto<string> {
  url: string;
}

export interface GenerativeQuestionAnsweringExcludeUrlCreateDto {
  url: string;
}

export interface GenerativeQuestionAnsweringDataDto extends FullAuditedEntityDto<string> {
  file: GenerativeQuestionAnsweringFileDto;
  url: GenerativeQuestionAnsweringUrlDto;
  trainStatus: TrainStatus;
  type: GenerativeQuestionAnsweringFileType;
  isRemoved: boolean;
  isFile: boolean;
}

export interface GenerativeQuestionAnsweringTrainStatusDto {
  status: GenerativeQuestionAnsweringTrainStatus;
  trainDisabled: boolean;
  trainTooltip: string;
  trainLoading: boolean;
  trainLoadingTooltip: string;
}

export interface GenerativeQuestionAnsweringTrainedNotificationModel {
  projectId: string;
  name: string;
}

export interface GenerativeQuestionAnsweringTrainFailedNotificationModel {
  projectId: string;
  name: string;
}

export interface PublishExportStatusDto {
  currentVersionId: string;
  isPublishButtonEnabled: boolean;
  isExportButtonEnabled;
  publishTooltip: string;
  exportTooltip: string;
}

export interface ProjectPublishStatusDto {
  canBePublished: boolean;
  canBeExported: boolean;
  publishErrorMessage: string;
  exportErrorMessage: string;
}

export interface GenerateProjectDto {
  name: string;
  description: string;
  intentCount: number;
  utteranceCount: number;
  primaryLanguageCode: string;
}
